import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import ReconnectingWebSocket from 'reconnecting-websocket';
import {
  mainAction,
  putReadInteraction,
  getClientInfoForNewClientInteraction,
  getMMInfoForNewClientInteraction,
  logUIErrors,
} from "redux/actions/interactionsActions";
import { SFNotifications } from "utils/notifications";
import { getAssociateInformation } from "./redux/actions/userInfoActions";

import {
  UPDATE_INTERACTIONS,
  GET_OFFICE_PREFERENCES,
  GET_INTERACTIONS,
  GET_CUSTOMER_PREFERENCES,
  MESSAGE_DELIVERY_STATUS,
  UPDATE_MESSAGE_DELIVERY_STATUS,
  UPDATE_DO_NOT_TEXT_STATUS_BAR,
  CHANGE_FILTER_OPTIONS,
  UPDATE_CHAT_LOG,
  SET_AZURE_TOKEN,
  HIDE_INTERACTION,
  UNHIDE_INTERACTION,
  ASSIGN_INTERACTION,
  UNASSIGN_INTERACTION,
} from "redux/actions/types.js";

import "./scss/main.scss";

import Home from "./pages/Home";
import Search from "./pages/Search";
import Gateway from "./pages/gateway";
import Moa from "./components/Moa";
import GenericError from "./pages/genericError";
import Signup from "./pages/signup";
import SFConnectLogo from "./components/Common/sf-logo";
import { sfc_routes } from "./utils/general";

import Header from "./global/components/Header";
import { websocketHandler, wsReadyState } from "./utils/websocketHandler";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webSocket: null,
      azureToken: localStorage.getItem("token"),
      hasAzureToken: true,
      noTokenCounter: 0,
      loading: true,
      redirectError: false,
    };
  }

  // If the token from local storage is not expired then set azure token to Redux storage and
  // restart the token check loop.
  setAzureToken = (value) => {
    if (value) {
      const decodedJwt = parseJwt(value);
      const exp = decodedJwt.exp * 1000;
      if (exp > Date.now()) {
        this.props.actions.mainAction(SET_AZURE_TOKEN, value);
        this.refreshToken(value);
      }
    }
  };

  // Get token from local storage and check if it is equals to the previous one.
  // If it is not, store the new token on Redux and refresh the websocket connection.
  updateAzureTokenFromStorage(previousToken) {
    const currentToken = localStorage.getItem("token");
    this.setState({
      loading: false,
      azureToken: currentToken,
      hasAzureToken: true,
    });
    this.setAzureToken(this.state.azureToken);
    // If this is the first token then get associate information and open websocket connection.
    if (!previousToken && currentToken) {
      this.props.actions.getAssociateInformation(currentToken);
      websocketHandler.connect(process.env.REACT_APP_AWS_WSS_URL, [
        "X-SF_AZURE_TOKEN",
        this.state.azureToken,
      ]);
    }
    // If it is a new token then refresh websocket connection.
    else if (previousToken && currentToken !== previousToken) {
      websocketHandler.refresh(["X-SF_AZURE_TOKEN", this.state.azureToken]);
    }
  }

  // Check for new token every minute.
  refreshToken(token) {
    const waitAMinute = 60 * 1000;
    setTimeout(() => {
      this.updateAzureTokenFromStorage(token);
    }, waitAMinute);
  }

  componentDidMount() {
    // Wait one second and update redux with the token from local storage
    setTimeout(() => {
      this.updateAzureTokenFromStorage();
    }, 1000);
  }

  componentDidUpdate() {
    if (
      this.props.webSocket &&
      this.props.webSocket.readyState === wsReadyState.OPEN
    ) {
      this.props.webSocket.onmessage = (payload) => {
        let response = JSON.parse(payload.data);
        if (!response.replay) {
          switch (response.type) {
            case "applicationPreference": {
              break;
            }
            case "clientPreference": {
              this.props.actions.mainAction(GET_CUSTOMER_PREFERENCES, response);
              //To show Do not Text status bar if it changes in a WS push
              this.props.actions.mainAction(
                UPDATE_DO_NOT_TEXT_STATUS_BAR,
                response
              );
              break;
            }
            case 'interaction_delete' :
              {
                if (
                  this.props.associateId !== "" &&
                  this.props.associateId === response.data.interaction.Item.ownerAgent.associateID
                ) {
                  this.props.actions.mainAction(GET_INTERACTIONS, {
                    response: response,
                    currentOfficeMembers:
                      this.props.userInfoReducer.currentOfficeMembers,
                  });     
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    endlessScroll: true,
                  }); 
                }
                break;
              }
            case "interaction": {
              //if check for restricting read/unread interactions to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.ownerAgent.associateID
              ) {
                this.props.actions.mainAction(GET_INTERACTIONS, {
                  response: response,
                  currentOfficeMembers:
                    this.props.userInfoReducer.currentOfficeMembers,
                });
                //If check New filter being selected and the incoming WS message is stating it is read, which means user clicked on a New message.
                //In order to avoid the interaction from disappearing from the New list, we remove the New filter selection so user can reply to the message without disruption.
                if (
                  this.props.interactionsReducer.filterOptions.MessageStatus ===
                    "Unread" &&
                  this.props.currentInteraction.isMessageViewed
                ) {
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    name: "MessageStatus",
                    value: " ",
                    isNewlyRead: true,
                    endlessScroll: true,
                  });
                } else {
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    endlessScroll: true,
                  });
                }
              }
              break;
            }
            case "message": {
              //if check for restricting messages to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.ownerAgent.associateID
              ) {
                //For customer-initiated messages, an extra call to get the customer detail needs to be made.
                let isNewInteraction = this.props.interactions.filter(
                  (interaction) =>
                    interaction.interactionId === response.data.interactionID ||
                    interaction.clientId === response.data.ownerClient.clientID
                );
                if (
                  isNewInteraction.length === 0 &&
                  response.data.ownerClient.type === "client"
                ) {
                  this.props.actions.getClientInfoForNewClientInteraction(
                    this.props.token,
                    response
                  );
                } else if (
                  isNewInteraction.length === 0 &&
                  response.data.ownerClient.type === "multi-match"
                ) {
                  this.props.actions.getMMInfoForNewClientInteraction(
                    this.props.token,
                    response,
                    this.props.userInfoReducer.loggedInAssociateId
                  );
                } else {
                  this.props.actions.mainAction(UPDATE_INTERACTIONS, {
                    data: response.data,
                  });
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    endlessScroll: true,
                  });
                }
                if (response.data.sender.type !== "associate") {
                  const getInteractionCount = (interactions) => {
                    let unreadCount = 0;
                    interactions.forEach(function (interaction) {
                      if (interaction.unreadClientMessageCount) {
                        unreadCount += interaction.unreadClientMessageCount;
                      }
                    });
                    return unreadCount;
                  };

                  SFNotifications({
                    interactionId: response.data.interactionID,
                    token: this.props.token,
                    associateId: this.props.associateId,
                    unreadCount: getInteractionCount(
                      this.props.interactionsReducer.interactions
                    ),
                  });
                }
                //if check for being on current Interaction
                if (
                  response.data.interactionID ===
                  this.props.currentInteraction.interactionId
                ) {
                  this.props.actions.mainAction(UPDATE_CHAT_LOG, {
                    data: response.data,
                    currentOfficeMembers:
                      this.props.userInfoReducer.currentOfficeMembers,
                    currentInteraction: this.props.currentInteraction,
                  });

                  //call PUT Interactions to mark this as read
                  this.props.actions.putReadInteraction(
                    this.props.token,
                    this.props.currentInteraction.associateId,
                    this.props.currentInteraction.interactionId,
                    this.props.currentInteraction.clientId,
                    0
                  );
                  if (
                    response.data.sender.type !== "associate" &&
                    (this.props.messageDeliveryStatus !== "sending" ||
                      this.props.messageDeliveryStatus !== "delayed")
                  ) {
                    this.props.actions.mainAction(
                      UPDATE_MESSAGE_DELIVERY_STATUS,
                      response
                    );
                  }
                }
              }

              break;
            }
            case "officePreference": {
              //if check for restricting office settings to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.agentID
              ) {
                this.props.actions.mainAction(
                  GET_OFFICE_PREFERENCES,
                  response.data
                );
              }

              break;
            }
            case "userPreference": {
              break;
            }
            case "messageDeliveryStatus": {
              //if check for restricting messages indicator to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.agentID
              ) {
                this.props.actions.mainAction(
                  MESSAGE_DELIVERY_STATUS,
                  response
                );
                if (
                  response.data.reports[0].status &&
                  response.data.reports[0].status === 5
                ) {
                  this.props.actions.logUIErrors(
                    response.data.agentID,
                    JSON.stringify(response),
                    "MessageDeliveryStatus WS",
                    false
                  );
                }
              }
              break;
            }
            case "interaction_update": {
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.agentAssociateId
              ) {
                if (response.subEvents.includes("hide")) {
                  this.props.actions.mainAction(HIDE_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers:
                      this.props.userInfoReducer.currentOfficeMembers,
                  });
                } else if (response.subEvents.includes("unhide")) {
                  this.props.actions.mainAction(UNHIDE_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers:
                      this.props.userInfoReducer.currentOfficeMembers,
                  });
                } else if (response.subEvents.includes("assignment")) {
                  this.props.actions.mainAction(ASSIGN_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers:
                      this.props.userInfoReducer.currentOfficeMembers,
                  });
                } else if (response.subEvents.includes("unassignment")) {
                  this.props.actions.mainAction(UNASSIGN_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers:
                      this.props.userInfoReducer.currentOfficeMembers,
                  });
                }
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      };
    }
  }

  render() {
    if (this.state.loading) {
      return <SFConnectLogo animate="true" />;
    } else {
      return (
        <Router>
          <Header />
          <Switch>
            <Route
              path={sfc_routes.pages.moa}
              render={(props) => (
                <Moa {...props} webSocket={this.props.webSocket} />
              )}
            />
            <Route path={sfc_routes.pages.search} component={Search} />
            <Route path={sfc_routes.pages.error} component={GenericError} />
            <Route path={sfc_routes.pages.signup} component={Signup} />
            <Route path={sfc_routes.pages.gateway} component={Gateway} />
            <Route
              path={sfc_routes.pages.home}
              render={(props) => <Home {...props} />}
            />
          </Switch>
        </Router>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  token: state.authReducer.token,
  associateId: state.userInfoReducer.officeAssociateId,
  currentInteraction: state.interactionsReducer.currentInteraction,
  interactionsReducer: state.interactionsReducer,
  interactions: state.interactionsReducer.interactions,
  userInfoReducer: state.userInfoReducer,
  messageDeliveryStatus: state.interactionsReducer.messageDeliveryStatus,
  webSocket: state.webSocketReducer.webSocket,
});
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        mainAction,
        getAssociateInformation,
        putReadInteraction,
        getClientInfoForNewClientInteraction,
        getMMInfoForNewClientInteraction,
        logUIErrors,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
